export default {
    init() {
        // JavaScript to be fired on all pages
        $('.front__team__members__slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            autoplaySpeed: 2500,
            prevArrow: $('.front__team__navigation__prev'),
            nextArrow: $('.front__team__navigation__next'),
            autoplay: true,
            swipeToSlide: true,
            slidesToShow: 2,
            centerMode: false,
            draggable: true,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 520,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $('.logos__landingspagina__slider__holder').slick({
            dots: false,
            arrows: false,
            // prevArrow: $('.logos__landingspagina__slider__navigation--prev'),
            // nextArrow: $('.logos__landingspagina__slider__navigation--next'),
            infinite: true,
            autoplaySpeed: 2500,
            autoplay: true,
            speed: 500,
            swipeToSlide: true,
            slidesToShow: 8,
            centerMode: false,
            draggable: true,
            responsive: [
                {
                    breakpoint: 1536,
                    settings: {
                        slidesToShow: 6,
                    },
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        });

        $('.testimonial__landingspage__slider').slick({
            dots: false,
            arrows: true,
            // prevArrow: $('.testimonial__landingspage__slider__navigation--prev'),
            // nextArrow: $('.testimonial__landingspage__slider__navigation--next'),
            infinite: true,
            speed: 500,
            swipeToSlide: true,
            slidesToShow: 4,
            centerMode: false,
            draggable: true,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $('.faq__landingspage__items__item__button').on('click', function () {
            let answer = $(this).siblings('.faq__landingspage__items__item__answer');

            if (answer.css('max-height') === '0px') {
                answer.css('max-height', answer.prop('scrollHeight') + 'px');
                $(this).find('.faq__landingspage__items__item__button__icon--minus').css('display', 'inline-flex');
                $(this).find('.faq__landingspage__items__item__button__icon--plus').css('display', 'none');
            } else {
                answer.css('max-height', '0px');
                $(this).find('.faq__landingspage__items__item__button__icon--minus').css('display', 'none');
                $(this).find('.faq__landingspage__items__item__button__icon--plus').css('display', 'inline-flex');
            }
        });

        $('.front__projects__slider').slick({
            dots: true,
            navigation: false,
            pauseOnHover: false,
            infinite: true,
            speed: 500,
            autoplaySpeed: 2500,
            autoplay: true,
            swipeToSlide: false,
            slidesToShow: 1,
            centerMode: false,
            draggable: false,
        });

        $('.agent__item').on('click', function () {
            if ($(this).hasClass('agent__item__open')) {
                $('.agent__item').removeClass('agent__item__open')
                $('.agent__item').find('.agent__item__content').slideUp('fast');
                $('.agent__item').css('z-index', '0')
            } else {
                $('.agent__item').removeClass('agent__item__open')
                $('.agent__item').find('.agent__item__content').slideUp('fast');
                $('.agent__item').css('z-index', '0')

                $(this).addClass('agent__item__open')
                $(this).css('z-index', '1')
                $(this).find('.agent__item__content').slideDown('fast');
            }
        });

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        // hover image sectoren

        var myInterval;  // Declare it on global scope.

        $('.sector-block').mouseover(function () {
            $(this).find('img.img-loop').data('old-src', $(this).find('img.img-loop').attr('src'));
            var alt_src = $(this).find('img.img-loop').data('alt-src').split(';');

            var that = $(this).find('img.img-loop');
            var i = 0;
            myInterval = setInterval(function () {
                // Set an interval
                if (i == alt_src.length) {
                    i = 0;
                    that.attr('src', that.data('old-src'));
                } else {
                    that.attr('src', alt_src[i]);
                    i++;
                }
            }, 500);  // Interval delay in millisecs.
        })
            .mouseout(function () {
                clearInterval(myInterval);  // Clear the interval
                $(this).find('img.img-loop').attr('src', $(this).find('img.img-loop').data('old-src'));
            });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
